
import { computed, defineComponent, reactive, ref, unref, watch, onMounted } from "vue";
import type { FormInstance } from "element-plus";
import { useI18n } from "vue-i18n";
import tools from "@/utils/tools";
export default defineComponent({
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 800,
    },
    // 当前弹窗的价格信息
    planInfo: {
      type: Object,
    },
    // 是否是热卖套餐
    showSave: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const i18n = useI18n();
    const visible = computed(() => props.visible);
    const ruleFormRef = ref<FormInstance>();
    const formData = reactive({
      email: "",
      code: "",
      inputUserName: false,
    });
    const originUrl = ref(1)
    const validateUserNameASCII = (
      rule: any,
      value: string,
      callback: () => void
    ) => {
      var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      formData.email = value.replace(re, "");
      callback();
    };

    let UserNameTrim = async () => {
      formData.email = tools.LTrim(tools.RTrim(formData.email));
    };

    const emailRule = computed(() => {
      const rule = reactive({
        email: [
          { validator: validateUserNameASCII, trigger: "blur" },
          {
            required: true,
            message: i18n.t("pleaseEnterEmailAddress"),
            trigger: "blur",
          },
          {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: i18n.t("invalidEmailFormat"),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^\S{1,100}$/,
            message: i18n.t("invalid Email Format"),
            trigger: "blur",
          },
        ],
      });
      return rule;
    });
    const closeDialog = () => {
      context.emit("closeDialog");
    };

    let checkEamil = () => {
      // unref 如果参数是一个 ref 则返回它的 value，否则返回参数本身。
      const formRef: any = unref(ruleFormRef);
      let aaa = formRef.validateField("email", (valid: boolean) => {
        if (valid) {
          formData.inputUserName = true;
        } else {
          formData.inputUserName = false;
        }
      });
      // console.log("aaaa", aaa);

      return aaa;
    };

    let toContinue = async () => {
      await checkEamil();
      console.log("inputUserName", formData.inputUserName);

      if (!formData.inputUserName) {
        return;
      }
      // let params = {
      //   email: formData.email,
      //   code: formData.code,
      // };
      // 需要传递过去发送网络请求的参数
      let requestDate = {
        email: formData.email,
        code: formData.code,
      };

      context.emit("toPay", requestDate);

      // console.log("params", params);
    };
    onMounted(() => {
      if (window.location.origin.includes('famikeep')) {
        originUrl.value = 0
      }
    })
    return {
      visible,
      formData,
      emailRule,
      UserNameTrim,
      toContinue,
      ruleFormRef,
      closeDialog,
      originUrl
    };
  },
});
